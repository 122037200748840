import React, { useState, useEffect } from "react"
import { Link, useTranslation } from "../../i18n"
import * as actions from "../../store/actions"
import { useSelector, useDispatch } from "react-redux"
import gql from "graphql-tag"
import { useQuery, useMutation } from "@apollo/client"
import { subscriptionDetails } from "../../forms/user"
import { GET_PERSONAL_DETAILS, UPDATE_PERSONAL_DETAILS } from "../../graphql.my/queries"

import FormGenerator from "../../Layout/Forms/FormGenerator"
import StripeButton from "../../Layout/Components/StripeButton"
import { FormatDate } from "../../i18n"

import { isPast, parseISO } from "date-fns"

const Stripe: React.FC<any> = (props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const userId = useSelector((state) => state.auth.payload.userData?.userId)
  const stripeCustomerId = useSelector((state) => state.auth.payload.userData?.stripeCustomerId)
  const subscribedUntil = useSelector((state) => state.auth.payload.userData?.subscribedUntil)
  const discountCode = useSelector((state) => (state.auth.payload.userData ? state.auth.payload.userData["custom:discountCode"] : null))

  const [subscriptionMode, setSubscriptionMode] = useState(false)
  const [gotoPortal, setGotoPortal] = useState(false)

  //----------------------------------------------------------------------------------------------------------------
  // Feedback from stripe

  let suggestGoingToGroups = false
  let canceled = false
  if (typeof window !== "undefined") {
    const params = new URLSearchParams(location.search)
    if (params.get("session_id") != null) suggestGoingToGroups = true
    if (params.get("canceled") != null) canceled = true
  }

  useEffect(() => {
    if (suggestGoingToGroups) {
      // Send event to GTM
      window.dataLayer.push({
        event: "subscription-paid",
      })
    }
  }, [])

  useEffect(() => {
    if (discountCode != null && discountCode != "") {
      subscriptionDetails["discountPromo"] = {
        type: "component",
        component: (
          <div style={{ marginTop: "1rem", marginLeft: "1rem", backgroundColor: "#aca", padding: "2rem" }}>
            <h2>Ricordati dello sconto!</h2>
            <p style={{ marginBottom: 0, paddingBottom: 0 }}>
              Cliccando sul bottone qui sotto verrai reindirizzatə al portale Stripe per il pagamento. Ricordati di inserire il tuo codice promozionale<br></br>
            </p>
            <div style={{ padding: "1rem", color: "#fff", fontSize: "2.4rem", fontWeight: "bold", marginLeft: "1rem" }}>{discountCode}</div>
            <p style={{ marginTop: 0, paddingTop: 0 }}>per ottenere il 50% di sconto sulla quota associativa ad Ecoverso di quest’anno.</p>
          </div>
        ),
      }
    }
  }, [discountCode])

  //----------------------------------------------------------------------------------------------------------------
  // Form

  const { loading, error, data: userData, refetch } = useQuery(gql(GET_PERSONAL_DETAILS), { variables: { id: userId } })
  const [updateUser, { error: mutationError }] = useMutation(gql(UPDATE_PERSONAL_DETAILS))

  if (error || mutationError) {
    dispatch(actions.notificationAdd({ message: t("API Error"), variant: "DangerIcon" }))
    console.log("API Error", error, mutationError)
    return null
  }

  // If the auth token is refreshed, refetch the data
  const refreshed = useSelector((state) => state.auth.payload.userData?.iat)
  useEffect(() => {
    refetch()
  }, [refreshed])

  const onSubmit = (values, setSubmitting) => {
    values.displayName = values.firstName + " " + values.lastName
    updateUser({ variables: { ...values, acptStatute: 1, id: userId } })
      .then(() => {
        setGotoPortal(true)
      })
      .catch((error) => {
        dispatch(actions.notificationAdd({ message: t("API Error"), variant: "DangerIcon" }))
        console.log("API Error", error, mutationError)
      })
  }

  const filterValues = (values) => {
    // Se country == null rimuovilo in modo da mantenere il default
    let initialValues: any = {}
    initialValues = { ...userData?.getUser }
    if (initialValues.country == null) delete initialValues.country
    return initialValues
  }

  //----------------------------------------------------------------------------------------------------------------
  // On first load refresh token (in case we land here after checkout)

  useEffect(() => {
    if (userData && (userData?.getUser?.stripeCustomerId != stripeCustomerId || userData?.getUser?.subscribedUntil != subscribedUntil)) {
      dispatch(actions.refreshAuthToken())
    }
  }, [userData])

  //----------------------------------------------------------------------------------------------------------------

  const stripePortal = (
    <div style={{ marginTop: "3rem" }}>
      <h2>{t("Portale Stripe")}</h2>
      <p>
        In Ecoverso, per professionalità e sicurezza, abbiamo scelto di avvalerci di{" "}
        <a href="https://stripe.com" target="_blank">
          Stripe
        </a>{" "}
        per i pagamenti.
      </p>
      <p>
        Cliccando sul bottone qui sotto verrai reindirizzatə al portale Stripe, dove potrai gestire i metodi di pagamento della tua quota associativa ad
        Ecoverso.
      </p>
      <StripeButton mode="portal">vai al portale Stripe</StripeButton>
    </div>
  )

  const subscribe = (
    <div style={{ marginTop: "3rem" }}>
      <h2>{t("Associati ad Ecoverso")}</h2>
      {subscriptionMode ? (
        <p>
          Per associarti compila il form sottostante, al termine avverrà il reindirizzamento al portale di Stripe dove sarà possibile pagare la quota
          associativa
        </p>
      ) : (
        <p>Non risulti associatə ad Ecoverso, clicca sul bottone sottostante per procedere</p>
      )}

      {!loading && subscriptionMode ? (
        <React.Fragment>
          <FormGenerator
            properties={subscriptionDetails}
            values={filterValues(userData?.getUser)}
            submitButtonText={t("Salva e vai al pagamento")}
            onSubmit={onSubmit}
            onCancel={() => {
              setSubscriptionMode(false)
            }}
          />
          {gotoPortal ? (
            <StripeButton mode="subscribe" autorun={true}>
              Vai automaticamente al portale stripe
            </StripeButton>
          ) : null}
        </React.Fragment>
      ) : (
        <button
          className="btn btn--primary"
          onClick={() => {
            setSubscriptionMode(true)
          }}
        >
          Associati
        </button>
      )}
    </div>
  )

  const subscribed = (
    <div style={{ marginTop: "3rem" }}>
      <h2>{t("Associatə Ecoverso")}</h2>
      <p>
        Risulti associatə ad ecoverso fino al <FormatDate date={subscribedUntil} prefix=" " />
      </p>
    </div>
  )

  return (
    <React.Fragment>
      <h1>{t("Abbonamento e pagamenti")}</h1>
      <p>
        Utilizziamo i fondi delle quote associative per migliorare i servizi che offriamo ai nostri associati, sia online sul nostro sito sia in presenza ai
        nostri eventi, e per promuovere ancora di più la mobilità sostenibile in Italia.
      </p>

      {!subscribedUntil || isPast(parseISO(subscribedUntil)) ? subscribe : subscribed}

      {suggestGoingToGroups ? (
        <div style={{ marginTop: "3rem", backgroundColor: "#aca", padding: "2rem" }}>
          <h2>Pagamento confermato.</h2>
          <p>
            Ti sei appena associatə per terminare l'acquisto in un gruppo?<br></br>
            <b>Ricordati di tornare a compilare il form dello step 3</b>
          </p>
          <Link to="/app/profile/groups" className="btn btn--primary">
            Vai ai tuoi gruppi per continuare
          </Link>
          <p>
            <br />
            <b>ATTENZIONE</b>: Se il form dello Step 3 ti chiede nuovamente il pagamento della quota associativa, aspetta un paio d'ore e poi ricarica la
            pagina.
          </p>
        </div>
      ) : null}

      {canceled ? (
        <div style={{ marginTop: "3rem", backgroundColor: "#caa", padding: "2rem" }}>
          <h2>Pagamento cancellato.</h2>
          <p style={{ marginBottom: 0 }}>Clicca sul bottone qui sopra se vuoi riprovare</p>
        </div>
      ) : null}

      {stripeCustomerId && !subscriptionMode ? stripePortal : null}
    </React.Fragment>
  )
}

export default Stripe
