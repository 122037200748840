import React from "react"

import { differenceInCalendarDays, isFuture, isPast, parseISO } from "date-fns"

import { Steps } from "antd"
import { FormatDate, Link } from "../../../i18n"

const { Step } = Steps

type Props = {
  id?: string
  disableNotifications?: string
  registrationDate?: string
  participationDate?: string
  choosingDate?: string
  notInterestInBuying?: number
  confirmationDate?: string
  definitelyNotInterestedInBuying?: number
  BuyingGroup?: {
    id?: string
    name?: string
    uidCMS?: string
    pubblicationStartDate?: string
    pubblicationEndDate?: string
    registrationStartDate?: string
    registrationEndDate?: string
    participationStartDate?: string
    participationEndDate?: string
    choosingStartDate?: string
    choosingEndDate?: string
    confirmationStartDate?: string
    confirmationEndDate?: string
    resellerFlowStartDate?: string
    resellerFlowEndDate?: string
    uniteChooseAndConfirm?: number
  }
}

export const GroupAsParticipant: React.FC<Props> = (props) => {
  // console.log(props)

  const registrationDate = props.registrationDate != null ? parseISO(props.registrationDate) : null
  const participationDate = props.participationDate != null ? parseISO(props.participationDate) : null
  const choosingDate = props.choosingDate != null ? parseISO(props.choosingDate) : null
  const confirmationDate = props.confirmationDate != null ? parseISO(props.confirmationDate) : null

  const pubblicationStartDate = props.BuyingGroup.pubblicationStartDate != null ? parseISO(props.BuyingGroup.pubblicationStartDate) : null
  const pubblicationEndDate = props.BuyingGroup.pubblicationEndDate != null ? parseISO(props.BuyingGroup.pubblicationEndDate) : null
  const registrationStartDate = props.BuyingGroup.registrationStartDate != null ? parseISO(props.BuyingGroup.registrationStartDate) : null
  const registrationEndDate = props.BuyingGroup.registrationEndDate != null ? parseISO(props.BuyingGroup.registrationEndDate) : null
  const participationStartDate = props.BuyingGroup.participationStartDate != null ? parseISO(props.BuyingGroup.participationStartDate) : null
  const participationEndDate = props.BuyingGroup.participationEndDate != null ? parseISO(props.BuyingGroup.participationEndDate) : null
  const choosingStartDate = props.BuyingGroup.choosingStartDate != null ? parseISO(props.BuyingGroup.choosingStartDate) : null
  const choosingEndDate = props.BuyingGroup.choosingEndDate != null ? parseISO(props.BuyingGroup.choosingEndDate) : null
  const confirmationStartDate = props.BuyingGroup.confirmationStartDate != null ? parseISO(props.BuyingGroup.confirmationStartDate) : null
  const confirmationEndDate = props.BuyingGroup.confirmationEndDate != null ? parseISO(props.BuyingGroup.confirmationEndDate) : null

  const reachedStep = props.confirmationDate ? 4 : props.choosingDate ? 3 : props.participationDate ? 2 : 1

  return (
    <div className="groupDetailsCard">
      <div>
        {pubblicationEndDate == null || isFuture(pubblicationEndDate) ? (
          <Link to={`group:${props.BuyingGroup.uidCMS}`} className="groupDetailsCard-button btn btn--primary btn-sm">
            VAI AL GRUPPO
          </Link>
        ) : (
          <button disabled className="groupDetailsCard-button btn btn--disabled btn-sm">
            gruppo concluso
          </button>
        )}
        <div className="groupDetailsCard-groupName">Gruppo {props.BuyingGroup.name}</div>
        <Steps size="small" current={reachedStep - 1} className="site-navigation-steps">
          {props.BuyingGroup.uniteChooseAndConfirm ? null : (
            <React.Fragment>
              <Step
                title={reachedStep > 1 ? "Partecipato" : "Partecipa"}
                // subTitle="00:00:05"
                status={
                  reachedStep > 1
                    ? "finish"
                    : isFuture(participationStartDate) || !participationStartDate
                    ? "wait"
                    : isPast(participationEndDate)
                    ? "error"
                    : "process"
                }
                description={
                  participationDate != null ? (
                    <React.Fragment>
                      il <FormatDate date={participationDate} format="dd/MM" />
                    </React.Fragment>
                  ) : isFuture(participationStartDate) ? (
                    `fra ${-differenceInCalendarDays(new Date(), participationStartDate)}gg`
                  ) : participationStartDate == null ? (
                    `TBD`
                  ) : isFuture(participationEndDate) ? (
                    `hai ${-differenceInCalendarDays(new Date(), participationEndDate)}gg`
                  ) : isPast(participationEndDate) ? (
                    "" //chiuso
                  ) : (
                    ""
                  ) //aperto
                }
              />
              <Step
                title={reachedStep > 2 ? "Scelto" : "Scegli"}
                // subTitle="00:00:05"
                status={reachedStep > 2 ? "finish" : isFuture(choosingStartDate) || !choosingStartDate ? "wait" : isPast(choosingEndDate) ? "error" : "process"}
                description={
                  choosingDate != null ? (
                    <React.Fragment>
                      il <FormatDate date={choosingDate} format="dd/MM" />
                    </React.Fragment>
                  ) : isFuture(choosingStartDate) ? (
                    `fra ${-differenceInCalendarDays(new Date(), choosingStartDate)}gg`
                  ) : choosingStartDate == null ? (
                    `TBD`
                  ) : isFuture(choosingEndDate) ? (
                    `hai ${-differenceInCalendarDays(new Date(), choosingEndDate)}gg`
                  ) : isPast(choosingEndDate) ? (
                    "" //chiuso
                  ) : (
                    ""
                  ) //aperto
                }
              />
            </React.Fragment>
          )}
          <Step
            title={reachedStep > 3 ? "Confermato" : "Conferma"}
            // subTitle="00:00:05"
            status={
              reachedStep > 3
                ? "finish"
                : isFuture(confirmationStartDate) || !confirmationStartDate
                ? "wait"
                : isPast(confirmationEndDate)
                ? "error"
                : "process"
            }
            description={
              confirmationDate != null ? (
                <React.Fragment>
                  il <FormatDate date={confirmationDate} format="dd/MM" />
                </React.Fragment>
              ) : isFuture(confirmationStartDate) ? (
                `fra ${-differenceInCalendarDays(new Date(), confirmationStartDate)}gg`
              ) : confirmationStartDate == null ? (
                `TBD`
              ) : isFuture(confirmationEndDate) ? (
                `hai ${-differenceInCalendarDays(new Date(), confirmationEndDate)}gg`
              ) : isPast(confirmationEndDate) ? (
                "" //chiuso
              ) : (
                ""
              ) //aperto
            }
          />
        </Steps>
      </div>
    </div>
  )
}

export default GroupAsParticipant
