import React from "react"
import { checkIfAuthorized } from "./utils/utils"
import { useDocumentTitle } from "./utils/customHooks"

import { useSelector } from "react-redux"
import { useStaticQuery, graphql } from "gatsby"
import ClientSideSuspense from "./ClientSideSuspense"

import { Router } from "../i18n"

export interface IAppRoute {
  label?: string
  component?: React.ComponentType<any>
  componentRest?: { [x: string]: any }
  path?: string
  exact?: boolean
  title?: string
  requires?: string | string[]
  routes?: IAppRoutes
  popConfirm?: {
    title: string
    okText: string
    cancelText: string
    placement?: string
    onConfirm?: Function
    onCancel?: Function
  }
}

export type IAppRoutes = IAppRoute[]

const RouteWithTitleUpdates = ({ component: Component, title, ...rest }: IAppRoute) => {
  useDocumentTitle(title)
  return <Component {...rest} />
}

const PageNotFound = (props) => {
  useDocumentTitle(props.title)
  return <div>Not found</div>
}

const LazyComponent = (WrappedComponent) => {
  return class extends React.Component {
    render() {
      // Wraps the input component in a container, without mutating it. Good!
      return (
        <ClientSideSuspense>
          <WrappedComponent {...this.props} />
        </ClientSideSuspense>
      )
    }
  }
}

const AppRoutes: React.FC<{
  routes: IAppRoutes
  basepath?: string
  className?: string
}> = ({ routes, basepath, className }) => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn)
  const roles = useSelector((state) => (state.auth.isLoggedIn ? state.auth.payload.role : []))
  const data = useStaticQuery(graphql`
    query SiteTitle {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  // Single route renderer function
  const renderRoute = (route: IAppRoute, idx: number) => {
    const toBeRendered = route.component != undefined && checkIfAuthorized(route.requires, isLoggedIn, roles)

    if (toBeRendered) {
      return <RouteWithTitleUpdates path={route.path} component={route.component} key={idx} title={route.title + " | " + data.site.siteMetadata.title} />
    }
    return null
  }

  const routerProps = basepath ? { basepath } : {}

  return (
    <Router {...routerProps} className={className}>
      {routes.map((e1, i1) => {
        if (e1.routes !== undefined) {
          // it's a group of routes, if authorized dive in
          if (checkIfAuthorized(e1.requires, isLoggedIn, roles))
            return e1.routes.map((e2, i2) => {
              if (e2.routes !== undefined) {
                return e2.routes.map((e3, i3) => renderRoute(e3, i3))
              } else {
                return renderRoute(e2, i2)
              }
            })
        } else {
          return renderRoute(e1, i1)
        }
      })}
      <PageNotFound default title={data.site.siteMetadata.title} />
    </Router>
  )
}

export { AppRoutes, LazyComponent }
