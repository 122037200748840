import React from "react"
import GridLayout from "../Layout/GridLayout"
import { Seo } from "../seo"
import { IAppRoutes, AppRoutes } from "../Layout/Routing"
import { useI18next } from "../i18n"

import Profile from "../User/Profile"

const AppPage = (props) => {
  const { t } = useI18next()
  const routes: IAppRoutes = [
    {
      component: Profile,
      // label: "User Profile",
      path: "/app/profile/*",
      title: "Profilo Utente",
      requires: "AUTH",
    },
  ]

  //console.log("Context: ", props.pageContext)

  return (
    <GridLayout>
      <Seo title={t("Profilo utente")} />
      <AppRoutes routes={routes} className="row row--full row--no-padding " />
    </GridLayout>
  )
}

export default AppPage
