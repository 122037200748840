import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useQuery, useMutation } from "@apollo/client"

import { differenceInCalendarDays, isFuture, isPast, parseISO } from "date-fns"

import gql from "graphql-tag"
import { GET_ALL_GROUPS } from "../../graphql.my/queries"
import { Drawer, Table, Tag } from "antd"
import { FormatDate } from "../../i18n"

import ManageGroup from "./Components/ManageGroup"

import _ from "lodash"

const tagColors = { UNDEFINED: "red", PAST: "gold", CURRENT: "green", FUTURE: "lime" }
const tagOrder = { UNDEFINED: 1, PAST: 2, CURRENT: 3, FUTURE: 4 }

//----------------------------------------------------------------------------------------------------
// Definizione delle colonne
const getColumns = (additionalColumns: string[] = [], refetch?: Function) => {
  const columns = []
  columns.push({
    title: "Gruppo",
    dataIndex: "name",
    key: "name",
    sorter: (a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0),
    defaultSortOrder: "ascend",
    width: 200,
    fixed: "left",
  })

  columns.push({
    title: "P",
    dataIndex: "P",
    key: "P",
    sorter: (a, b) => (tagOrder[a.P] + " " + a.pDate.toISOString() > tagOrder[b.P] + " " + b.pDate.toISOString() ? 1 : -1),
    render: (P) => (
      <React.Fragment>
        <Tag color={tagColors[P]} key={P}>
          {P.charAt(0)}
        </Tag>{" "}
      </React.Fragment>
    ),
    filters: Object.keys(tagOrder).map((tagName) => {
      return { text: tagName, value: tagName }
    }),
    onFilter: (value, record) => record.P == value,
  })
  columns.push({
    title: "S1",
    dataIndex: "S1",
    key: "S1",
    sorter: (a, b) => (tagOrder[a.S1] > tagOrder[b.S1] ? 1 : -1),
    render: (S1) => (
      <React.Fragment>
        <Tag color={tagColors[S1]} key={S1}>
          {S1.charAt(0)}
        </Tag>{" "}
      </React.Fragment>
    ),
    filters: Object.keys(tagOrder).map((tagName) => {
      return { text: tagName, value: tagName }
    }),
    onFilter: (value, record) => record.S1 == value,
  })
  columns.push({
    title: "S2",
    dataIndex: "S2",
    key: "S2",
    sorter: (a, b) => (tagOrder[a.S2] > tagOrder[b.S2] ? 1 : -1),
    render: (S2) => (
      <React.Fragment>
        <Tag color={tagColors[S2]} key={S2}>
          {S2.charAt(0)}
        </Tag>{" "}
      </React.Fragment>
    ),
    filters: Object.keys(tagOrder).map((tagName) => {
      return { text: tagName, value: tagName }
    }),
    onFilter: (value, record) => record.S2 == value,
  })
  columns.push({
    title: "S3",
    dataIndex: "S3",
    key: "S3",
    sorter: (a, b) => (tagOrder[a.S3] > tagOrder[b.S3] ? 1 : -1),
    render: (S3) => (
      <React.Fragment>
        <Tag color={tagColors[S3]} key={S3}>
          {S3.charAt(0)}
        </Tag>{" "}
      </React.Fragment>
    ),
    filters: Object.keys(tagOrder).map((tagName) => {
      return { text: tagName, value: tagName }
    }),
    onFilter: (value, record) => record.S3 == value,
  })
  columns.push({
    title: "R",
    dataIndex: "R",
    key: "R",
    sorter: (a, b) => (tagOrder[a.R] > tagOrder[b.R] ? 1 : -1),
    render: (R) => (
      <React.Fragment>
        <Tag color={tagColors[R]} key={R}>
          {R.charAt(0)}
        </Tag>{" "}
      </React.Fragment>
    ),
    filters: Object.keys(tagOrder).map((tagName) => {
      return { text: tagName, value: tagName }
    }),
    onFilter: (value, record) => record.R == value,
  })

  columns.push({
    title: "",
    dataIndex: "id",
    key: "id",
    render: (id) => (
      <ManageGroup buyingGroupId={id} as="admin">
        <a className="groupDetailsCard-button btn btn--primary btn-sm">GO</a>
      </ManageGroup>
    ),
    width: 100,
    fixed: "right",
  })

  return columns
}
//----------------------------------------------------------------------------------------------------

const Admin: React.FC<any> = (props) => {
  const { loading, error, data: queryData, refetch } = useQuery(gql(GET_ALL_GROUPS))

  const [groupsData, setGroupsData] = useState({} as any)
  const [tableData, setTableData] = useState([])
  const [tableColumns, setTableColumns] = useState(getColumns(props.as))
  const [filteredCount, setFilteredCount] = useState(-1)

  useEffect(() => {
    const theGroupsData = queryData?.searchBuying_groups
    setGroupsData(theGroupsData)
    //console.log("Query: ", theGroupsData)

    //----------------------------------------------------------------------------------------------------------------
    // Prepare data for table
    const additionalColumns = []

    if (theGroupsData && Array.isArray(theGroupsData)) {
      const tmpTableData = theGroupsData.map((groupData, i) => {
        const pubblicationStartDate = groupData.pubblicationStartDate != null ? parseISO(groupData.pubblicationStartDate) : null
        const pubblicationEndDate = groupData.pubblicationEndDate != null ? parseISO(groupData.pubblicationEndDate) : null
        const participationStartDate = groupData.participationStartDate != null ? parseISO(groupData.participationStartDate) : null
        const participationEndDate = groupData.participationEndDate != null ? parseISO(groupData.participationEndDate) : null
        const choosingStartDate = groupData.choosingStartDate != null ? parseISO(groupData.choosingStartDate) : null
        const choosingEndDate = groupData.choosingEndDate != null ? parseISO(groupData.choosingEndDate) : null
        const confirmationStartDate = groupData.confirmationStartDate != null ? parseISO(groupData.confirmationStartDate) : null
        const confirmationEndDate = groupData.confirmationEndDate != null ? parseISO(groupData.confirmationEndDate) : null
        const resellerFlowStartDate = groupData.resellerFlowStartDate != null ? parseISO(groupData.resellerFlowStartDate) : null
        const resellerFlowEndDate = groupData.resellerFlowEndDate != null ? parseISO(groupData.resellerFlowEndDate) : null

        return {
          id: groupData.id,
          name: groupData.name,
          P:
            pubblicationStartDate == null
              ? "UNDEFINED"
              : isFuture(pubblicationStartDate)
              ? "FUTURE"
              : isPast(pubblicationStartDate) && (isFuture(pubblicationEndDate) || pubblicationEndDate == null)
              ? "CURRENT"
              : "PAST",
          pDate: pubblicationStartDate,
          S1:
            participationStartDate == null
              ? "UNDEFINED"
              : isFuture(participationStartDate)
              ? "FUTURE"
              : isPast(participationStartDate) && (isFuture(participationEndDate) || participationEndDate == null)
              ? "CURRENT"
              : "PAST",
          S2:
            choosingStartDate == null
              ? "UNDEFINED"
              : isFuture(choosingStartDate)
              ? "FUTURE"
              : isPast(choosingStartDate) && (isFuture(choosingEndDate) || choosingEndDate == null)
              ? "CURRENT"
              : "PAST",
          S3:
            confirmationStartDate == null
              ? "UNDEFINED"
              : isFuture(confirmationStartDate)
              ? "FUTURE"
              : isPast(confirmationStartDate) && (isFuture(confirmationEndDate) || confirmationEndDate == null)
              ? "CURRENT"
              : "PAST",
          R:
            resellerFlowStartDate == null
              ? "UNDEFINED"
              : isFuture(resellerFlowStartDate)
              ? "FUTURE"
              : isPast(resellerFlowStartDate) && (isFuture(resellerFlowEndDate) || resellerFlowEndDate == null)
              ? "CURRENT"
              : "PAST",
        }
      })

      setTableData(tmpTableData)
      setTableColumns(getColumns(additionalColumns, refetch))
      //console.log("Table: ", tmpTableData)
    }
    //----------------------------------------------------------------------------------------------------------------
  }, [queryData, error])

  return (
    <React.Fragment>
      <h1>Tutti i gruppi</h1>
      <Table
        columns={tableColumns}
        dataSource={tableData}
        pagination={false}
        footer={() => `Totale: ${tableData.length} ` + (filteredCount != -1 && filteredCount != tableData.length ? `- Filtrati: ${filteredCount}` : "")}
        size="small"
        scroll={{ x: "max-content", y: "100%" }}
        sticky={true}
        onChange={(pagination, filters, sorter, extra) => {
          setFilteredCount(extra.currentDataSource?.length)
        }}
      />
    </React.Fragment>
  )
}

export default Admin
