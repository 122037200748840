import React, { useState, useEffect } from "react"
import { useTranslation } from "../../i18n"
import { IAppRoutes, AppRoutes } from "../../Layout/Routing"
import Navigation from "../../Layout/Navigation"
import General from "./General"
import Groups from "./Groups"
import TimeAndLanguage from "./TimeAndLanguage"
import Policies from "./Policies"
import Stripe from "./Stripe"
import Admin from "./Admin"

import UploadAvatar from "./UploadAvatar"
import { Icon } from "../../Layout/Components"

import { useSelector } from "react-redux"

const Profile: React.FC<any> = (props) => {
  const { t } = useTranslation()
  const avatarUrl = useSelector((state) => state.auth.payload.userData?.avatarUrl)
  const stripeCustomerId = useSelector((state) => state.auth.payload.userData?.stripeCustomerId)

  // If the auth token is refreshed, hideUploadAvatar
  const refreshed = useSelector((state) => state.auth.payload.userData?.iat)
  useEffect(() => {
    setShowUploadAvatar(false)
  }, [refreshed])

  const routes: IAppRoutes = []

  routes.push({
    component: Admin,
    label: t("Admin"),
    path: "/profile/admin",
    // exact: true,
    title: t("Profilo Utente > Admin"),
    requires: "ADMIN",
  })

  routes.push({
    component: General,
    label: t("Il mio profilo"),
    path: "/profile",
    // exact: true,
    title: t("Profilo Utente"),
    requires: "AUTH",
  })

  // routes.push({
  //   component: TimeAndLanguage,
  //   icon: null,
  //   label: t("Time & Language"),
  //   path: "/profile/time-language",
  //   // exact: true,
  //   title: t("User Profile > Time & Language"),
  //   requires: "AUTH",
  // })

  routes.push({
    component: Groups,
    label: t("I miei gruppi"),
    path: "/profile/groups",
    // exact: true,
    title: t("Profilo Utente > Gruppi"),
    requires: "AUTH",
  })

  routes.push({
    component: Policies,
    label: t("Le mie policy"),
    path: "/profile/policies",
    // exact: true,
    title: t("Profilo Utente > Policy"),
    requires: "AUTH",
  })

  routes.push({
    component: Stripe,
    label: t("Quota associativa e pagamenti"),
    path: "/profile/subscription",
    title: t("Profilo Utente > Quota associativa e pagamenti"),
    requires: "AUTH",
  })

  routes.push({
    label: t("Logout"),
    path: "/logout",
    exact: true,
    requires: "AUTH",
    popConfirm: {
      title: "Vuoi veramente effettuare il logout?",
      okText: "Si, esci.",
      cancelText: "No",
      placement: "bottomRight",
      onConfirm: () => {
        if (window !== undefined) window.location.href = "/logout"
      },
    },
  })

  const [showUploadAvatar, setShowUploadAvatar] = useState(false)

  return (
    <React.Fragment>
      {/* <div className="row row--reset-grid"> */}
      <div className="row row--center">
        <div className="col col--4">
          <div className="profile-avatar">
            <div className="profile-avatar-image">{avatarUrl ? <img src={avatarUrl} /> : null}</div>
            <button className="btn btn-secondary profile-avatar-button" onClick={() => setShowUploadAvatar(true)}>
              <Icon name="camera" strokeWidth={1} />
            </button>
          </div>
          <Navigation routes={routes} mode="inline" basepath="/app" />
        </div>
        <div className="col col--1"></div>
        <div className="col col--7">
          <AppRoutes routes={routes} basepath="/app" />
        </div>
      </div>
      {showUploadAvatar ? <UploadAvatar onClose={() => setShowUploadAvatar(false)} /> : null}
    </React.Fragment>
  )
}

export default Profile
