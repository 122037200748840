import React, { useCallback, useState } from "react"
import { useDropzone } from "react-dropzone"
import { Icon, Overlay, Loading } from "../../Layout/Components"

import { useDispatch } from "react-redux"
import * as actions from "../../store/actions"

import { Storage } from "aws-amplify"
import { Progress } from "antd"

const UploadAvatar = ({ onClose }) => {
  const dispatch = useDispatch()

  const [preview, setPreview] = useState("")
  const [uploadProgress, setUploadProgress] = useState(-1)

  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    console.log("Drop: ", acceptedFiles, fileRejections)
    if (fileRejections.length > 0) {
      dispatch(
        actions.notificationAdd({
          message: "Errore caricamento immagine",
          description: "Sono accettati solo file .JPG o .PNG di dimensione massima 2 Mb",
          icon: <Icon name="image" size="l" strokeWidth={2} color="#ff6633" />,
          duration: 5,
        })
      )
    }
    if (acceptedFiles.length == 1) {
      setPreview(URL.createObjectURL(acceptedFiles[0]))
    }
  }, [])

  const confirmUpload = () => {
    setUploadProgress(0)
    console.log(acceptedFiles)
    const file = acceptedFiles[0]
    Storage.put("avatar." + (file.type == "image/jpeg" ? "jpg" : "png"), file, {
      progressCallback(progress) {
        //console.log(`Uploaded: ${progress.loaded}/${progress.total}`)
        setUploadProgress(Math.min(Math.ceil((progress.loaded / progress.total) * 100), 99))
      },
      level: "protected",
      contentType: file.type, //'image/png'
    })
      .then((result) => {
        console.log("OK: ", result)
        //setUploadProgress(99)
        setTimeout(() => onClose(), 10000)
      })
      .catch((err) => console.log("KO: ", err))
  }

  const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "image/jpeg, image/png",
    multiple: false,
    maxSize: 2048000,
  })

  return (
    <React.Fragment>
      <Overlay />
      <div className="upload-avatar__foreground">
        <div className="upload-avatar__modal">
          <div className="upload-avatar__title mb-xs">Carica o trascina qui la tua foto del profilo</div>

          <div
            {...getRootProps()}
            className={"upload-avatar__dropzone" + (preview ? " upload-avatar__dropzone--preview" : "")}
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              <div className="upload-avatar__dropzone-content">
                <p>Rilascia qui la tua immagine ...</p>
              </div>
            ) : (
              <div className="upload-avatar__dropzone-content">
                <Icon name="image" size="l" />
                <p>Trascina qui un'immagine JPG o PNG (max 2MB)</p>
                <button className="btn btn-secondary">Seleziona una foto dal tuo computer</button>
              </div>
            )}
          </div>
          {preview ? (
            <div className="upload-avatar__preview">
              <div className="upload-avatar__preview-image">
                <img src={preview} />
              </div>
              <button className="btn btn-primary upload-avatar__preview-confirm" onClick={confirmUpload}>
                Conferma
                {uploadProgress != -1 ? <Loading size="small" /> : null}
              </button>
            </div>
          ) : null}
          {uploadProgress != -1 ? (
            <Progress percent={uploadProgress} status="active" className="upload-avatar__progress" />
          ) : (
            <div className="upload-avatar__modal-close" onClick={() => onClose()}>
              <Icon name="x" />
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

export default UploadAvatar
