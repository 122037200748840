import React, { useState } from "react"

import { differenceInCalendarDays, isFuture, isPast, parseISO } from "date-fns"

import { FormatDate, Link } from "../../../i18n"
import { Steps, Drawer } from "antd"
import ManageGroup from "./ManageGroup"

const { Step } = Steps

type Props = {
  BuyingGroup?: {
    id?: string
    name?: string
    uidCMS?: string
    pubblicationStartDate?: string
    pubblicationEndDate?: string
    registrationStartDate?: string
    registrationEndDate?: string
    participationStartDate?: string
    participationEndDate?: string
    choosingStartDate?: string
    choosingEndDate?: string
    confirmationStartDate?: string
    confirmationEndDate?: string
    resellerFlowStartDate?: string
    resellerFlowEndDate?: string
    uniteChooseAndConfirm?: number
  }
}

export const GroupAsReseller: React.FC<Props> = (props) => {
  // console.log(props)

  const pubblicationStartDate = props.BuyingGroup.pubblicationStartDate != null ? parseISO(props.BuyingGroup.pubblicationStartDate) : null
  const pubblicationEndDate = props.BuyingGroup.pubblicationEndDate != null ? parseISO(props.BuyingGroup.pubblicationEndDate) : null
  const registrationStartDate = props.BuyingGroup.registrationStartDate != null ? parseISO(props.BuyingGroup.registrationStartDate) : null
  const registrationEndDate = props.BuyingGroup.registrationEndDate != null ? parseISO(props.BuyingGroup.registrationEndDate) : null
  const participationStartDate = props.BuyingGroup.participationStartDate != null ? parseISO(props.BuyingGroup.participationStartDate) : null
  const participationEndDate = props.BuyingGroup.participationEndDate != null ? parseISO(props.BuyingGroup.participationEndDate) : null
  const choosingStartDate = props.BuyingGroup.choosingStartDate != null ? parseISO(props.BuyingGroup.choosingStartDate) : null
  const choosingEndDate = props.BuyingGroup.choosingEndDate != null ? parseISO(props.BuyingGroup.choosingEndDate) : null
  const confirmationStartDate = props.BuyingGroup.confirmationStartDate != null ? parseISO(props.BuyingGroup.confirmationStartDate) : null
  const confirmationEndDate = props.BuyingGroup.confirmationEndDate != null ? parseISO(props.BuyingGroup.confirmationEndDate) : null
  const resellerFlowStartDate = props.BuyingGroup.resellerFlowStartDate != null ? parseISO(props.BuyingGroup.resellerFlowStartDate) : null
  const resellerFlowEndDate = props.BuyingGroup.resellerFlowEndDate != null ? parseISO(props.BuyingGroup.resellerFlowEndDate) : null

  return (
    <div className="groupDetailsCard">
      <div>
        {(resellerFlowEndDate == null || isFuture(resellerFlowEndDate)) && isPast(resellerFlowStartDate) ? (
          <ManageGroup buyingGroupId={props.BuyingGroup.id} as="reseller">
            <a className="groupDetailsCard-button btn btn--primary btn-sm">Gestisci gruppo</a>
          </ManageGroup>
        ) : (
          <button disabled className="groupDetailsCard-button btn btn--disabled btn-sm">
            Gestisci gruppo
          </button>
        )}
        <div className="groupDetailsCard-groupName">Gruppo {props.BuyingGroup.name}</div>
        <Steps size="small" className="site-navigation-steps">
          {props.BuyingGroup.uniteChooseAndConfirm ? null : (
            <React.Fragment>
              <Step
                title="Partecipa"
                status={isFuture(participationStartDate) || !participationStartDate ? "wait" : isPast(participationEndDate) ? "error" : "process"}
                description={
                  <React.Fragment>
                    dal <FormatDate date={participationStartDate} format="dd/MM" /> al <FormatDate date={participationEndDate} format="dd/MM" />
                  </React.Fragment>
                }
              />
              <Step
                title="Scegli"
                status={isFuture(choosingStartDate) || !choosingStartDate ? "wait" : isPast(choosingEndDate) ? "error" : "process"}
                description={
                  <React.Fragment>
                    dal <FormatDate date={choosingStartDate} format="dd/MM" /> al <FormatDate date={choosingEndDate} format="dd/MM" />
                  </React.Fragment>
                }
              />
            </React.Fragment>
          )}
          <Step
            title="Conferma"
            status={isFuture(confirmationStartDate) || !confirmationStartDate ? "wait" : isPast(confirmationEndDate) ? "error" : "process"}
            description={
              <React.Fragment>
                dal <FormatDate date={confirmationStartDate} format="dd/MM" /> al <FormatDate date={confirmationEndDate} format="dd/MM" />
              </React.Fragment>
            }
          />
        </Steps>
      </div>
    </div>
  )
}

export default GroupAsReseller
