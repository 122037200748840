import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useQuery, useMutation } from "@apollo/client"

import gql from "graphql-tag"
import { GET_ALL_MY_GROUPS } from "../../graphql.my/queries"
import GroupAsParticipant from "./Components/GroupAsParticipant"
import GroupAsReseller from "./Components/GroupAsReseller"

import _ from "lodash"

const Groups: React.FC<any> = (props) => {
  const userId = useSelector((state) => state.auth.payload.userData?.userId)

  const { loading, error, data: userData, refetch } = useQuery(gql(GET_ALL_MY_GROUPS), { variables: { id: userId } })

  const [userInGroups, setUserInGroups] = useState([])
  const [resellerInGroups, setResellerInGroups] = useState([])

  useEffect(() => {
    // console.log("Data: ", userData)
    if (Array.isArray(userData?.getUser?.UserInBuyingGroups)) {
      // Order Groups as participant
      let tmpUserGroups = _.cloneDeep(userData.getUser.UserInBuyingGroups)
      tmpUserGroups.sort((first, second) => (second.registrationDate > first.registrationDate ? 1 : -1))
      setUserInGroups(tmpUserGroups)
    }

    if (Array.isArray(userData?.getUser?.Organization?.ResellerIn)) {
      // Order groups as reseller (filter null groups, which are future groups)
      let tmpResellerGroups = _.cloneDeep(userData.getUser.Organization.ResellerIn).filter((record) => record.BuyingGroup != null)
      tmpResellerGroups.sort((first, second) => (second.BuyingGroup.resellerFlowStartDate > first.BuyingGroup.resellerFlowStartDate ? 1 : -1))
      setResellerInGroups(tmpResellerGroups)
    }
  }, [userData, error])

  return (
    <React.Fragment>
      <h1>I miei gruppi</h1>
      {userInGroups.map((userInGroup, i) => {
        return <GroupAsParticipant {...userInGroup} key={i} />
      })}
      {resellerInGroups.length > 0 ? (
        <React.Fragment>
          <h1>Rivenditore nei gruppi</h1>
          {resellerInGroups.map((resellerInGroup, i) => {
            return <GroupAsReseller {...resellerInGroup} key={i} />
          })}
        </React.Fragment>
      ) : null}
    </React.Fragment>
  )
}

export default Groups
