import React, { useState, useRef } from "react"
// import { matchPath } from "react-router"
import { IAppRoute } from "./Routing"

import { Menu, Popconfirm } from "antd"
import { Link } from "../i18n"
import { Match, navigate } from "@reach/router"
import { useSelector } from "react-redux"
import { checkIfAuthorized } from "./utils/utils"
const { SubMenu } = Menu

const Navigation: React.FC<any> = ({ routes, basepath, ...rest }) => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn)
  const roles = useSelector((state) => (state.auth.isLoggedIn ? state.auth.payload.role : []))

  const [selectedKeys, setSelectedKeys] = useState([])
  const [openKeys, setOpenKeys] = useState([])

  // Render single nav item
  const renderNavItem = (route: IAppRoute, key: string) => {
    return (
      route.label &&
      checkIfAuthorized(route.requires, isLoggedIn, roles) && (
        <Menu.Item key={key}>
          {route.popConfirm ? (
            <Popconfirm {...route.popConfirm}>
              <Link
                to={route.exact ? route.path : basepath ? basepath + route.path : route.path}
                getProps={(props) => {
                  if (props.isCurrent && !selectedKeys.includes(key)) {
                    setSelectedKeys([key])
                  }
                  return null
                }}
              >
                {route.label}
              </Link>
            </Popconfirm>
          ) : (
            <Link
              to={route.exact ? route.path : basepath ? basepath + route.path : route.path}
              getProps={(props) => {
                if (props.isCurrent && !selectedKeys.includes(key)) {
                  setSelectedKeys([key])
                }
                return null
              }}
            >
              {route.label}
            </Link>
          )}
        </Menu.Item>
      )
    )
  }

  // Render single Match item (detect location <-> menu open/selected)
  const renderMatchItem = (route: IAppRoute, key: string) => {
    return (
      route.label &&
      checkIfAuthorized(route.requires, isLoggedIn, roles) && (
        <Match key={"M" + key} path={route.exact ? route.path : basepath ? basepath + route.path : route.path}>
          {(props) => {
            if (props.match && !selectedKeys.includes(key)) {
              //console.log("MSelected: ", key, basepath ? basepath + route.path : route.path)
              //console.log("MOpen: ", key.indexOf(".") != -1 ? key.substr(0, key.indexOf(".")) : key)
              setSelectedKeys([key])
              const SubMenuKey = key.indexOf(".") != -1 ? key.substr(0, key.indexOf(".")) : key
              if (!openKeys.includes(SubMenuKey)) setOpenKeys([...openKeys, SubMenuKey])
            }
            return null
          }}
        </Match>
      )
    )
  }

  // Manage openKeys only for "inline"
  if (rest.mode == "inline") {
    rest.openKeys = openKeys
  }

  return (
    <React.Fragment>
      {routes.map((e1, i1) => {
        // Render <Match> components for each menu voice to detect currently opened url on page load (https://reach.tech/router/api/Match)
        const k1 = "M" + i1
        if (e1.routes !== undefined) {
          return (
            <React.Fragment key={i1}>
              {e1.path != undefined ? renderMatchItem(e1 as IAppRoute, k1) : null}
              {checkIfAuthorized(e1.requires, isLoggedIn, roles) &&
                e1.routes.map((e2, i2) => {
                  if (e2.routes !== undefined) {
                    return e2.routes.map((e3, i3) => renderMatchItem(e3 as IAppRoute, k1 + "." + i2 + "." + i3))
                  } else {
                    return renderMatchItem(e2 as IAppRoute, k1 + "." + i2)
                  }
                })}
            </React.Fragment>
          )
        } else {
          return renderMatchItem(e1 as IAppRoute, k1)
        }
      })}

      <Menu theme={rest.theme ? "dark" : "light"} selectedKeys={selectedKeys} {...rest}>
        {
          // Render all 1st level voices
          routes.map((e1, i1) => {
            const k1 = "M" + i1
            // if the 1st element is of IAppRoutesGroup
            if (e1.routes !== undefined) {
              // Go through level 2 elements
              return (
                checkIfAuthorized(e1.requires, isLoggedIn, roles) && (
                  <SubMenu
                    key={k1}
                    title={e1.label}
                    onTitleClick={() => {
                      // Handle sub-menu open/close because we've ovveridden openKeys to open submenu on page load
                      //console.log(openKeys, k1, openKeys.includes(k1))
                      if (openKeys.includes(k1)) {
                        //console.log("Close", k1)
                        setOpenKeys(openKeys.filter((e) => e != k1))
                      } else {
                        //console.log("Open", k1)
                        setOpenKeys([...openKeys, k1])
                      }
                      // It it's also a page open it on click
                      if (e1.path != undefined) navigate(basepath ? basepath + e1.path : e1.path)
                    }}
                  >
                    {
                      // Render all 2nd level voices
                      e1.routes.map((e2, i2) => {
                        // if the 2nd element is of IAppRoutesGroup
                        if (e2.routes !== undefined) {
                          return (
                            <Menu.ItemGroup key={k1 + "." + i2} title={e2.label}>
                              {e2.routes.map((e3, i3) => renderNavItem(e3 as IAppRoute, k1 + "." + i2 + "." + i3))}
                            </Menu.ItemGroup>
                          )
                        } else {
                          return renderNavItem(e2 as IAppRoute, k1 + "." + i2)
                        }
                      })
                    }
                  </SubMenu>
                )
              )
            } else {
              return renderNavItem(e1 as IAppRoute, k1)
            }
          })
        }
      </Menu>
    </React.Fragment>
  )
}

export default Navigation
