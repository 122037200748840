import React, { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import * as actions from "../../store/actions"
import { useI18next } from "../../i18n"

import { API } from "aws-amplify"
import { loadStripe } from "@stripe/stripe-js"
import currentEnv from "../../../currentEnv"

import { navigate } from "gatsby"
import { Loading } from "../../Layout/Components"
import { faSolarSystem } from "@fortawesome/pro-solid-svg-icons"
import { faLoveseat } from "@fortawesome/pro-regular-svg-icons"

const stripePromise = loadStripe(currentEnv == "dev" ? "pk_test_SqzeCr5gTUsm6cxK1IHFRQ0G00gQiZYX6r" : "pk_live_gPMfbGYYhRf57LVVlghxu56400Roglcsn5")

type StripeProps = {
  mode: "subscribe" | "portal" | "checkout"
  type?: "primary" | "secondary"
  autorun?: boolean
  children?: any
}

const StripeButton: React.FC<StripeProps> = (props) => {
  props = {
    mode: "portal",
    type: "primary",
    autorun: false,
    ...props,
  }

  const { t } = useI18next()
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)

  const redirectToSubscribe = async () => {
    setIsLoading(true)
    const session = await API.post("stripeAPI", "/subscribe", {
      body: {},
      headers: {},
    })
    if (session.statusCode >= 400) {
      dispatch(actions.notificationAdd({ message: t("API Error"), variant: "DangerIcon" }))
      console.log("API Error", session)
    } else {
      const sessionId = session.id
      const stripe = await stripePromise
      stripe.redirectToCheckout({ sessionId })
    }
  }

  const redirectToCheckout = async () => {
    // const session = await API.post("stripeAPI", "/checkout", {
    //   body: {
    //     quantity: 1,
    //     client_reference_id: "UniqueString",
    //     priceId: "price_1IyGpCFN5ffr5lQE3faMbnyV",
    //   },
    //   headers: {},
    // })
    // const sessionId = session.id
    // const stripe = await stripePromise
    // stripe.redirectToCheckout({ sessionId })
  }

  const redirectToPortal = async () => {
    setIsLoading(true)
    const session = await API.post("stripeAPI", "/portal", {
      body: {},
      headers: {},
    })
    console.log("Session: ", session)
    setIsLoading(false)
    if (session.statusCode >= 400) {
      dispatch(actions.notificationAdd({ message: t("API Error"), variant: "DangerIcon" }))
      console.log("API Error", session)
    } else {
      const url = session.url
      if (typeof window !== "undefined") {
        navigate(session.url)
      }
    }
  }

  useEffect(() => {
    if (props.autorun) {
      console.log("Redirect")
      switch (props.mode) {
        case "subscribe":
          redirectToSubscribe()
          break
        case "checkout":
          redirectToCheckout()
          break
        case "portal":
          redirectToPortal()
          break
      }
    }
  }, [])

  if (props.autorun) {
    return null
  } else {
    switch (props.mode) {
      case "subscribe":
        return (
          <button className={`btn btn--${props.type}`} onClick={redirectToSubscribe} disabled={isLoading}>
            {props.children}
            {isLoading ? <Loading size="small" /> : null}
          </button>
        )
        break

      case "checkout":
        return (
          <button className={`btn btn--${props.type}`} onClick={redirectToCheckout} disabled={isLoading}>
            {props.children}
            {isLoading ? <Loading size="small" /> : null}
          </button>
        )
        break

      case "portal":
        return (
          <button className={`btn btn--${props.type}`} onClick={redirectToPortal} disabled={isLoading}>
            {props.children}
            {isLoading ? <Loading size="small" /> : null}
          </button>
        )
        break
    }
  }
}

export default StripeButton
