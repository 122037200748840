import React from "react"

import {
  firstName,
  lastName,
  birthDate,
  birthPlace,
  gender,
  displayName,
  email,
  mobile,
  address1,
  city,
  zipCode,
  state,
  country,
  declaredOrigin,
  statuteInfo,
  acptStatute,
  taxCode,
} from "./fields/user"

export const personalDetails = {
  declaredOrigin,
  row1: {
    type: "row",
    properties: {
      firstName,
      lastName,
    },
  },
  row2: {
    type: "row",
    properties: {
      birthDate,
      gender,
    },
  },
  //displayName: displayName,
  email: { ...email, disabled: true },
  text: {
    type: "component",
    component: (
      <p className="mb-s mt--s" style={{ color: "#999", textTransform: "none" }}>
        Se desideri modificare il tuo indirizzo email (o eliminare il tuo account) ti preghiamo di scriverci una mail a{" "}
        <a href="mailto:accounts@ecoverso.org">accounts@ecoverso.org</a>
      </p>
    ),
  },
  mobile,
  address1,
  city,
  row3: {
    type: "row",
    properties: {
      state,
      zipCode,
    },
  },
  country: { ...country, default: "IT" },
}

export const subscriptionDetails = {
  row1: {
    type: "row",
    properties: {
      firstName,
      lastName,
    },
  },
  email: { ...email, disabled: true },
  text: {
    type: "component",
    component: (
      <p className="mb-s mt--s" style={{ color: "#999", textTransform: "none" }}>
        Se desideri modificare il tuo indirizzo email (o eliminare il tuo account) ti preghiamo di scriverci una mail a{" "}
        <a href="mailto:accounts@ecoverso.org">accounts@ecoverso.org</a>
      </p>
    ),
  },
  mobile: { ...mobile, required: true },
  row2: {
    type: "row",
    properties: {
      birthDate: { ...birthDate, required: true },
      gender: { ...gender, required: true },
    },
  },
  birthPlace: { ...birthPlace, required: true },
  taxCode: { ...taxCode, required: true },
  address1: { ...address1, required: true },
  city: { ...city, required: true },
  row3: {
    type: "row",
    properties: {
      state: { ...state, required: true },
      zipCode: { ...zipCode, required: true },
    },
  },
  country: { ...country, default: "IT", required: true },
  statutoInfo: statuteInfo,
  acptStatute,
}
