import React, { useState, useEffect } from "react"
import * as actions from "../../store/actions"
import { useTranslation } from "../../i18n"
import { useSelector, useDispatch } from "react-redux"
import { useQuery, useMutation } from "@apollo/client"
import FormGenerator from "../../Layout/Forms/FormGenerator"
import { FormatDate } from "../../i18n"

import { Row, Col } from "antd"
import { Icon, Loading } from "../../Layout/Components"

import { personalDetails } from "../../forms/user"
import { GET_PERSONAL_DETAILS, UPDATE_PERSONAL_DETAILS } from "../../graphql.my/queries"
import gql from "graphql-tag"
import { isPast, parseISO } from "date-fns"

const General: React.FC<any> = (props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  //------------------------------------------------------------------------------------------------------
  // User data

  const userId = useSelector((state) => state.auth.payload.userData?.userId)

  const { loading, error, data: userData, refetch } = useQuery(gql(GET_PERSONAL_DETAILS), { variables: { id: userId } })
  const [updateUser, { error: mutationError }] = useMutation(gql(UPDATE_PERSONAL_DETAILS))

  if (error || mutationError) {
    dispatch(actions.notificationAdd({ message: t("API Error"), variant: "DangerIcon" }))
    console.log("API Error", error, mutationError)
    return null
  }

  const onSubmit = (values, setSubmitting) => {
    values.displayName = values.firstName + " " + values.lastName
    updateUser({ variables: { ...values, id: userId } })
      .then(() => {
        refetch()
        setSubmitting(false)
        setEditMode(false)
        //dispatch(actions.notificationAdd({ message: t("Saved"), variant: "SuccessIcon" }))
      })
      .catch((error) => {
        dispatch(actions.notificationAdd({ message: t("API Error"), variant: "DangerIcon" }))
        console.log("API Error", error, mutationError)
      })
  }

  // If the auth token is refreshed, refetch the data
  const refreshed = useSelector((state) => state.auth.payload.userData?.iat)
  useEffect(() => {
    refetch()
  }, [refreshed])

  useEffect(() => {
    // Elimina il campo declaredOrigin se già compilato
    if (userData?.getUser?.declaredOrigin) {
      delete personalDetails.declaredOrigin
    }
  }, [userData])

  const filterValues = (values) => {
    // Se country == null rimuovilo in modo da mantenere il default
    let initialValues: any = {}
    initialValues = { ...userData?.getUser }
    if (initialValues.country == null) delete initialValues.country
    //console.log("Init: ", initialValues)
    return initialValues
  }
  //------------------------------------------------------------------------------------------------------

  const [editMode, setEditMode] = useState(false)

  //console.log(userData)

  return (
    <React.Fragment>
      <Loading active={loading} />
      <div className="profile">
        <h1 className="profile__name">{userData?.getUser?.displayName}</h1>
        <div className="profile__info mb-m">
          {userData?.getUser?.roles ? (
            <div className="profile__role">
              <Icon name="award" size="s" className="mr-xxs" />
              {userData?.getUser?.roles?.replace(",", ", ")}
            </div>
          ) : null}
          {userData?.getUser?.subscribedSince ? (
            <div className="profile__role">
              <Icon name="calendar" size="s" className="mr-xxs" />
              Associato dal &nbsp;
              <FormatDate date={userData?.getUser?.subscribedSince} prefix=" " />
              {userData?.getUser?.subscribedUntil && isPast(parseISO(userData.getUser.subscribedUntil)) ? (
                <span>
                  &nbsp; al&nbsp;
                  <FormatDate date={userData?.getUser?.subscribedUntil} prefix=" " />
                </span>
              ) : null}
            </div>
          ) : null}
        </div>
        <div className="profile__info">
          <h2>I miei dati</h2>
          {!editMode ? (
            <button className="btn btn--ghost mr-none" onClick={() => setEditMode(true)}>
              Modifica
            </button>
          ) : null}
        </div>
        {editMode ? (
          <FormGenerator
            properties={personalDetails}
            values={filterValues(userData?.getUser)}
            submitButtonText={t("Salva modifiche")}
            onSubmit={onSubmit}
            onCancel={() => {
              setEditMode(false)
            }}
          />
        ) : (
          <React.Fragment>
            <Row>
              <Col span={12}>
                <div className="profile__label">nome</div>
                <div className="profile__data">{userData?.getUser?.firstName || "-"}</div>
              </Col>
              <Col span={12}>
                <div className="profile__label">cognome</div>
                <div className="profile__data">{userData?.getUser?.lastName || "-"}</div>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <div className="profile__label">Data di nascita</div>
                <div className="profile__data">
                  <FormatDate date={userData?.getUser?.birthDate} />
                </div>
              </Col>
              <Col span={12}>
                <div className="profile__label">Sesso</div>
                <div className="profile__data">{userData?.getUser?.gender || "-"}</div>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <div className="profile__label">email</div>
                <div className="profile__data">{userData?.getUser?.email}</div>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <div className="profile__label">cellulare</div>
                <div className="profile__data">{userData?.getUser?.mobile || "-"}</div>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <div className="profile__label">residenza</div>
                <div className="profile__data">
                  {userData?.getUser?.address1}
                  {userData?.getUser?.city ? ` - ${userData.getUser.city}` : ""}
                  {userData?.getUser?.state ? ` (${userData.getUser.state})` : ""}
                  {userData?.getUser?.zipCode ? `, ${userData.getUser.zipCode}` : ""}
                  {userData?.getUser?.country ? ` - ${userData.getUser.country}` : ""}
                </div>
              </Col>
            </Row>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  )
}

export default General
